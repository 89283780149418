const config = {
  clientId: 'LatamDatazapProd',
  authorizationEndpoint: 'https://ndcsso.asurion.com:9031/as/authorization.oauth2',
  tokenEndpoint: 'https://ndcsso.asurion.com:9031/as/token.oauth2',
  redirectSignIn: 'https://latam.prod.mobprod.asurion53.com/',
  redirectSignOut: 'https://latam.prod.mobprod.asurion53.com/',
  responseType: 'code',
  scope: ['openid', 'profile', 'email'],
  adminGroup: ['HQDOMAIN_Horizon App Support', 'HQDOMAIN_APP-PR-ALL-WB-LATAM-ANALYTICS-OFFSHORE'],
  gitrepo: 'prod',
  api: 'dWTS^L8S8os=rCRcoe0hvRaHV5D.G0Vn',
};

const lambdaURL = {
  URL: 'https://qxe2g867t9.execute-api.us-east-1.amazonaws.com/prod/'
};

module.exports = { config, lambdaURL };
