import classes from './Card.module.css';

function Card(props: any): JSX.Element {
  return <div className={classes.card}>{props.children}</div>;
}

function QueryCard(props:any) {
  return <div className={classes.querycard}>
    {props.children}
  </div>
}

export default Card;
export { QueryCard };

