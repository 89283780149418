import { DataGrid, GridToolbar, GridOverlay } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

const columns = [
  { field: 'id', headerName: 'ID', width: 80 },
  { field: 'subscriptionumber', headerName: 'SUBSCRIPTIONUMBER', width: 220 },
  { field: 'mobiledevicenumber', headerName: 'MOBILEDEVICENUMBER', width: 230 },
  { field: 'casenumber', headerName: 'CASENUMBER', width: 200 },
  { field: 'shipmentdate', headerName: 'SHIPMENTDATE', width: 220 },
  { field: 'transactionamount', headerName: 'TRANSACTIONAMOUNT', width: 250 },
  { field: 'status', headerName: 'STATUS', width: 130 },
  { field: 'outboundfilename', headerName: 'OUTBOUNDFILENAME', width: 250 },
  { field: 'notificationtype', headerName: 'NOTIFICATIONTYPE', width: 250 },
  { field: 'notificationdescription', headerName: 'NOTIFICATIONDESCRIPTION', width: 250 },
  { field: 'serviceordernumber', headerName: 'SERVICEORDERNUMBER', width: 250 },
  { field: 'storenumber', headerName: 'STORENUMBER', width: 200 },
  { field: 'originalassetimei', headerName: 'ORIGINALASSETIMEI', width: 250 },
  { field: 'originalassetmake', headerName: 'ORIGINALASSETMAKE', width: 250 },
  { field: 'originalassetmodel', headerName: 'ORIGINALASSETMODEL', width: 250 },
  { field: 'replacedassetimei', headerName: 'REPLACEDASSETIMEI', width: 250 },
  { field: 'replacedassetmake', headerName: 'REPLACEDASSETMAKE', width: 250 },
  { field: 'replacedassetmodel', headerName: 'REPLACEDASSETMODEL', width: 250 }
];

const keys = [
  'id',
  'subscriptionumber',
  'mobiledevicenumber',
  'casenumber',
  'shipmentdate',
  'transactionamount',
  'status',
  'outboundfilename',
  'notificationtype',
  'notificationdescription',
  'serviceordernumber',
  'storenumber',
  'originalassetimei',
  'originalassetmake',
  'originalassetmodel',
  'replacedassetimei',
  'replacedassetmake',
  'replacedassetmodel'
];

function Tablerow(props: any) {
  const [selectedRow, setSeletedrow] = useState({});
  const [isrowclick, setIsrowclick] = useState(false);

  //console.log('Table row props: ',props)
  const value = props.data;
  const obj = {} as any;
  const arr = [] as any;
  for (let i = 0; i < value.length; i++) {
    for (let k = 0; k < keys.length; k++) {
      obj[keys[k]] = value[i][k];
    }
    arr.push({ ...obj });
  }
  // print object
  //console.log('arr : ',arr);

  const history = useHistory();

  function CustomLoadingOverlay() {
    return (
      <GridOverlay>
        <div style={{ position: 'absolute', top: 0, width: '100%' }}>
          <LinearProgress />
        </div>
      </GridOverlay>
    );
  }

  return (
    <div>
      {isrowclick && (
        <Button
          type="submit"
          variant="contained"
          size="large"
          sx={{ ml: 2, mb: 2, pl: 5, pr: 5 }}
          onClick={() => history.push({ pathname: '/update', state: { selectedRow } })}
        >
          Update
        </Button>
      )}
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={arr}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
            LoadingOverlay: CustomLoadingOverlay
          }}
          loading={Boolean(props.load)}
          pageSize={10}
          rowsPerPageOptions={[5, 10, 20]}
          onRowClick={(data: any) => {
            setIsrowclick(true);
            setSeletedrow(data.row);
            // console.log('onRowClick : ', data);
          }}
        />
      </div>
    </div>
  );
}

export default Tablerow;
