import { config, lambdaURL } from '../config';
import { useState } from 'react';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import Tablerow from '../component/Table_main';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const URL = `${lambdaURL.URL}select`;
const req_header = { 'Content-Type': 'application/json','x-api-key': `${config.api}`}

function Main(): any {
  const [subscriptionNumber, setSubscriptionnumber] = useState('');
  const [shipmentDate, setShipmentdate] = useState('');
  const [rows, setRows] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [responsealert, setResponsealert] = useState('');
  
  function ScriptAlert() {
    return (
      <>
        <Alert severity="error" color="error">
          {responsealert}
        </Alert>
      </>
    );
  }

  function searchSubnum(e: any) {
    e.preventDefault();
    if (subscriptionNumber) {
      setisloading(true);

      //axios
      axios
        .get(`${URL}?action=search&subnum=${subscriptionNumber}`, {headers: req_header})
        .then((response: any) => {
          //console.log('SEARCH subscriptionNumber response data: ', response.data);
          if (Object.keys(response.data.message).length === 0) {
            alert('ERROR: Response message is empty. Please check cloudwatch logs');
            setResponsealert('ERROR: Response message is empty. Please check cloudwatch logs');
          } else {
            if (JSON.stringify(response.data.message).includes('ERROR:')) {
              alert('ERROR: ' + JSON.stringify(response.data.message));
              setResponsealert(JSON.stringify(response.data.message));
            } else {
              setRows(response.data.message);
            }
          }
          setisloading(false);
        })
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
    } else {
      alert('Please fill all the fields');
    }
  }

  function viewAll(e: any) {
    e.preventDefault();
    setisloading(true);
    // fetch("https://hesa5wahaa.execute-api.us-east-1.amazonaws.com/prod/second-lambda?action=viewall")
    //   .then((response) => {
    //     return response.json();
    //   })
    //   .then((data) => {
    //     setisloading(false);
    //     console.log("VIEWALL fetch response data: ", data.message);
    //     setRows(data.message);
    //   });
    axios
      .get(`${URL}?action=viewall&shipmentdate=${shipmentDate}`, { headers: req_header })
      .then((response: any) => {
        //console.log('VIEWALL response data: ', response.data);
        if (Object.keys(response.data.message).length === 0) {
          alert('ERROR: Response message is empty. Please check cloudwatch logs');
          setResponsealert('ERROR: Response message is empty. Please check cloudwatch logs');
        } else {
          if (JSON.stringify(response.data.message).includes('ERROR:')) {
            alert('ERROR: ' + JSON.stringify(response.data.message));
            setResponsealert(JSON.stringify(response.data.message));
          } else {
            setRows(response.data.message);
          }
        }
        setisloading(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  }

  return (
    <div>
      <Typography variant="h3" gutterBottom component="div" sx={{ ml: 2, mt: 3.5 }}>Dashboard</Typography>
      <Box component="form" sx={{ '& > :not(style)': { m: 1, width: '35ch' } }}>
        {responsealert && <ScriptAlert />}
        <TextField
          label="Subscription Number"
          multiline
          margin="normal"
          placeholder="Search" 
          size="small"
          value={subscriptionNumber}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
          onChange={(e) => {
            setSubscriptionnumber(e.target.value);
          }}
        />
        <Button type="submit" onClick={searchSubnum} variant="contained">
          search
        </Button>
        <div/>
        <TextField
          label="Shipment Date" size="small" helperText="YYYY-MM-DD ex.2022-04-01"
          onChange={(e) => {
            setShipmentdate(e.target.value);
          }}
          />

        <Button type="submit" onClick={viewAll} variant="contained">
          submit
        </Button>
      </Box>
      <Tablerow data={rows} load={isloading} />
    </div>
  );
}

export default Main;
