import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import { lambdaURL } from '../config';
import { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

const URL = `${lambdaURL.URL}first-lambda`;

const columns = [
  { field: 'id', headerName: 'Date Processed', width: 200 },
  { field: 'user', headerName: 'Executed by', width: 150 },
  { field: 'folder', headerName: 'Github repo', width: 250 },
  { field: 'rolloutquery', headerName: 'Rollout Query', width: 600 },
  { field: 'rollbackquery', headerName: 'Rollback Query', width: 600 }
];

function HistoryPage() {
  const [historydata, setHistorydata] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [responsealert, setResponsealert] = useState('');

  function CustomLoadingOverlay() {
    return (
      <GridOverlay>
        <div style={{ position: 'absolute', top: 0, width: '100%' }}>
          <LinearProgress />
        </div>
      </GridOverlay>
    );
  }

  useEffect(() => {
    setisloading(true);
    axios
      .post(`${URL}?action=gethistory`)
      .then((response: any) => {
        console.log('response data: ', response.data);
        if (Object.keys(response.data.message).length === 0) {
          alert('ERROR: Response message is empty. Please check cloudwatch logs');
          setResponsealert('ERROR: Response message is empty. Please check cloudwatch logs');
        } else {
          if (JSON.stringify(response.data.message).includes('ERROR:')) {
            alert('ERROR: ' + JSON.stringify(response.data.message));
            setResponsealert(JSON.stringify(response.data.message));
          } else {
            setHistorydata(response.data.message);
          }
        }
        setisloading(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  }, []);

  function ScriptAlert() {
    return (
      <>
        <Alert severity="error" color="error">
          {responsealert}
        </Alert>
      </>
    );
  }

  return (
    <div>
      <Typography variant="h3" gutterBottom component="div" sx={{ ml: 3, mt: 4 }}>History</Typography>
      {responsealert && <ScriptAlert />}
      <div style={{ marginLeft: 10, marginRight: 10, marginTop: 10, height: 600, width: '100%' }}>
        <DataGrid
          rows={historydata}
          columns={columns}
          components={{ LoadingOverlay: CustomLoadingOverlay }}
          loading={isloading}
          pageSize={10}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </div>
    </div>
  );
}

export default HistoryPage;
