import { lambdaURL, config } from '../config';
import { QueryCard } from '../component/ui/Card';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '../component/ui/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

interface UpdateConfig {
  state: {
    selectedRow: {
      serviceordernumber: string;
      transactionamount: string;
      subscriptionumber: string;
      id: string;
      status: string;
      mobiledevicenumber: string;
      originalassetimei: string;
      replacedassetimei: string;
    };
  };
}

const URL = `${lambdaURL.URL}update`;
const gitrepo = config.gitrepo;
const req_header = { 'Content-Type': 'application/json','x-api-key': `${config.api}`}


function UpdatePage(): JSX.Element {
  const userName = sessionStorage.getItem('userName');
  const ADgroup = sessionStorage.getItem('ADgroup') ? sessionStorage.getItem('ADgroup') : '';
  const timestamp = Date.now();
  const date = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  }).format(timestamp);
  const location = useLocation();
  const test = location as UpdateConfig;
  const [serviceOrderNumber, setServiceordernumber] = useState(
    test.state ? test.state.selectedRow.serviceordernumber : ''
  );
  const [transactionAmount, setTransactionamount] = useState(
    test.state ? test.state.selectedRow.transactionamount : ''
  );
  const [subscriptionNumber, setSubscriptionnumber] = useState(
    test.state ? test.state.selectedRow.subscriptionumber : ''
  );
  const [mobiledevicenumber, setMobiledevicenumber] = useState(
    test.state ? test.state.selectedRow.mobiledevicenumber : ''
  );
  const [originalassetimei, setOriginalassetimei] = useState(
    test.state ? test.state.selectedRow.originalassetimei : ''
  );
  const [replacedassetimei, setReplacedassetimei] = useState(
    test.state ? test.state.selectedRow.replacedassetimei : ''
  );
  const [id, setId] = useState(test.state ? test.state.selectedRow.id : '');
  const [rolloutquery, setRolloutquery] = useState('');
  const [rollbackquery, setRollbackquery] = useState('');
  const [rb_serviceOrderNumber, setRb_serviceordernumber] = useState(
    test.state ? test.state.selectedRow.serviceordernumber : ''
  );
  const [rb_transactionAmount, setRb_transactionamount] = useState(
    test.state ? test.state.selectedRow.transactionamount : ''
  );
  const [rb_subscriptionNumber, setRb_subscriptionnumber] = useState(
    test.state ? test.state.selectedRow.subscriptionumber : ''
  );
  const [rb_mobiledevicenumber, setRb_mobiledevicenumber] = useState(
    test.state ? test.state.selectedRow.mobiledevicenumber : ''
  );
  const [rb_originalassetimei, setRb_originalassetimei] = useState(
    test.state ? test.state.selectedRow.originalassetimei : ''
  );
  const [rb_replacedassetimei, setRb_replacedassetimei] = useState(
    test.state ? test.state.selectedRow.replacedassetimei : ''
  );
  const [rb_status, setRb_status] = useState(test.state ? test.state.selectedRow.status : '');
  const [outboundFilename, setoutboundFilename] = useState('');
  const [folderName, setFolderName] = useState('');
  let git_url: string;
  const [git_folder, setGit_folder] = useState('');
  const [responsealert, setResponsealert] = useState('');
  const [isprocessing, setIsprocessing] = useState(false);
  const [loadingbutton, setLoadingbutton] = useState(false);
  const [testdata, setTestdata] = useState(false);
  const [tabvalue, setTabValue] = useState('1');
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleTabChange = (event: any, newValue: any) => {
    setTabValue(newValue);
    setFolderName('');
    setIsprocessing(false);
    setResponsealert('');
    setTestdata(false);
  };

  const handleClickOpen = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = (e: any) => {
    e.preventDefault();
    setOpen(false);
  };

  const header = `--HZN Datazap Tool: Trigger datazap due to file failure (retrigger sending records to Claro by updating enrstaging.replacedassets status from processed to “new”)\n
  --Query executed on: ${date}
  --Query executed by: ${userName}\n\n`;

  function selectQuery(val: any) {
    switch (val) {
      case 'record':
        return `${header}UPDATE ENRSTAGING.REPLACEDASSETS \nSET
        STATUS = 'NEW', 
        SERVICEORDERNUMBER='${serviceOrderNumber}',
        SUBSCRIPTIONUMBER='${subscriptionNumber}',
        TRANSACTIONAMOUNT='${transactionAmount}',
        MOBILEDEVICENUMBER='${mobiledevicenumber}',
        ORIGINALASSETIMEI='${originalassetimei}',
        REPLACEDASSETIMEI='${replacedassetimei}' \nWHERE 1 = 1
        AND CLIENTCHANNELID = HEXTORAW('1C6B51B5A7AF1CD0E053183A060AC5A5')
        AND ID='${id}'; \nCOMMIT;`;
      
      case 'rbrecord':
        return `${header}UPDATE ENRSTAGING.REPLACEDASSETS \nSET
        STATUS = '${rb_status}',
        SERVICEORDERNUMBER='${rb_serviceOrderNumber}',
        SUBSCRIPTIONUMBER='${rb_subscriptionNumber}',
        TRANSACTIONAMOUNT='${rb_transactionAmount}',
        MOBILEDEVICENUMBER='${rb_mobiledevicenumber}',
        ORIGINALASSETIMEI='${rb_originalassetimei}',
        REPLACEDASSETIMEI='${rb_replacedassetimei}' \nWHERE ID='${id}'; \nCOMMIT;`;

      case 'batch':
        return `${header}UPDATE ENRSTAGING.REPLACEDASSETS \nSET
        STATUS = 'NEW' \nWHERE 1 = 1
        AND CLIENTCHANNELID = HEXTORAW('1C6B51B5A7AF1CD0E053183A060AC5A5')
        AND OUTBOUNDFILENAME='${outboundFilename}'; \nCOMMIT;`;

      case 'rbbatch':
        return `${header}UPDATE ENRSTAGING.REPLACEDASSETS \nSET
        STATUS = '${rb_status}' \nWHERE OUTBOUNDFILENAME='${outboundFilename}'; \nCOMMIT;`;

      case 'test_data':
        return `SELECT TO_CHAR (SYSDATE, 'MM-DD-YYYY HH24:MI:SS') "NOW" FROM DUAL; \nCOMMIT;`;

      default:
        return 'No sql statement match';
    }
  }

  function Buildrolloutquery(props: any) {
    const query = selectQuery(props.queryType);
    useEffect(() => {
      setRolloutquery(query);
    }, [query]);

    return (
      <>
        <Box>
          <QueryCard id="rollout-sql-card" variant="outlined">
            <CardContent>
              <div>{rolloutquery}</div>
            </CardContent>
          </QueryCard>
        </Box>
      </>
    );
  }

  function Buildrollbackquery(props: any) {
    const rbquery = selectQuery(props.queryType);
    useEffect(() => {
      setRollbackquery(rbquery);
    }, [rbquery]);

    return (
      <>
        <Box>
          <QueryCard id="rollback-sql-card" variant="outlined">
            <CardContent>
              <div>{rollbackquery}</div>
            </CardContent>
          </QueryCard>
        </Box>
      </>
    );
  }

  function ScriptInfo() {
    const handleClick = () => {
      window.open(`${git_folder}/${folderName}`);
    };

    return (
      <>
        <Alert severity="success" color="info">
          SQL script successfully loaded in{' '}
          <Link onClick={handleClick}>
            {git_folder}/{folderName}
          </Link>
          {'\n'}You may check the JIRA Github Workflow in # hzn-latam-datazap Slack channel
        </Alert>
      </>
    );
  }

  function ScriptAlert() {
    return (
      <>
        <Alert severity="error" color="error">
          {responsealert}
        </Alert>
      </>
    );
  }

  function ResponsiveDialog(props: any) {
    const formtype: string = props.formType;
    if (gitrepo === 'npr') {
      git_url = 'asurion-private/Hzn_Latam_Tool_npr.git';
      setGit_folder('https://github.com/asurion-private/Hzn_Latam_Tool_npr/tree/master/dataops');
    } else if (gitrepo === 'prod') {
      git_url = 'asurion-private/Hzn_Latam_Tool.git';
      setGit_folder('https://github.com/asurion-private/Hzn_Latam_Tool/tree/master/dataops');
    } else {
      setResponsealert('gitrepo no match found. Please check config file');
    }
    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          disableAutoFocus>
          <DialogTitle id="responsive-dialog-title">{'Update Changes'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>Are you sure you want to submit the changes below?</b>
              <p>
                <b>Github repository:</b>
              </p>
              <p>{git_folder}</p>
              <p>
                <b>Rollout Query:</b>
              </p>
              <p>{rolloutquery}</p>
              <p>
                <b>Rollback Query:</b>
              </p>
              <p>{rollbackquery}</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              No
            </Button>
            {formtype === 'submitForm' ? (
              <Button autoFocus onClick={submitForm}>
                Yes
              </Button>
            ) : (
              <Button autoFocus onClick={submitOutboundForm}>
                Yes
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  function sendRequest() {
    const req_data = {
      userName,
      date,
      git_url,
      rolloutquery,
      rollbackquery
    };
    console.log('request data is: ' + JSON.stringify(req_data));

    axios
      .post(`${URL}?action=update_github`, req_data, { headers: req_header })
      .then((response: any) => {
        console.log('response data: ', response.data);
        if (Object.keys(response.data.message).length === 0) {
          alert('ERROR: Response message is empty. Please check cloudwatch logs');
          setResponsealert('ERROR: Response message is empty. Please check cloudwatch logs');
        } else {
          if (JSON.stringify(response.data.message).includes('success')) {
            alert('Update complete');
            setFolderName(response.data.folder);
          } else {
            alert('ERROR: ' + JSON.stringify(response.data.message));
            setResponsealert(JSON.stringify(response.data.message));
          }
        }
        setLoadingbutton(false);
        setIsprocessing(false);
      })
      .catch((err) => {
        setLoadingbutton(false);
        setIsprocessing(false);
        console.log(err);
        alert(err.message);
      });
  }

  function submitForm(e: any) {
    e.preventDefault();
    setOpen(false);
    setIsprocessing(true);
    setLoadingbutton(true);
    if (serviceOrderNumber && transactionAmount) {
      sendRequest();
      setServiceordernumber('');
      setTransactionamount('');
      setSubscriptionnumber('');
      setId('');
      setMobiledevicenumber('');
      setOriginalassetimei('');
      setReplacedassetimei('');
      setRollbackquery('');
      setRolloutquery('');
    } else {
      alert('Record - Please fill all the fields');
    }
  }

  function submitOutboundForm(e: any) {
    e.preventDefault();
    setOpen(false);
    setIsprocessing(true);
    if (outboundFilename) {
      sendRequest();
      setoutboundFilename('');
      setRb_status('');
      setRollbackquery('');
      setRolloutquery('');
    } else {
      alert('Batch - Please fill all the fields');
    }
  }

  function LinearDeterminate() {
    const [progress, setProgress] = useState(10);

    useEffect(() => {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 0;
          }
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }, 500);

      return () => {
        clearInterval(timer);
      };
    }, []);

    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
    );
  }

  return (
    <div>
      <Card id="card-parent">
        <TabContext value={tabvalue}>
          <TabList onChange={handleTabChange}>
            <Tab label="By Record" value="1" />
            <Tab label="Batch" value="2" />
          </TabList>
          <TabPanel value="1">
            <Box
              component="form"
              sx={{ maxWidth: '100%', '& .MuiTextField-root': { m: 1 } }}
              onSubmit={handleClickOpen}>
              {folderName && <ScriptInfo />}
              {responsealert && <ScriptAlert />}
              {isprocessing && <LinearDeterminate />}
              <LoadingButton
                type="submit"
                variant="contained"
                endIcon={<SendIcon />}
                loading={loadingbutton}
                loadingPosition="end"
                size="large"
                sx={{ mb: 1, ml: 1, pl: 4, pr: 4 }}>
                Submit
              </LoadingButton>
              <ResponsiveDialog formType="submitForm" />
              {ADgroup === 'HQDOMAIN_Horizon App Support' && (
                <Button 
                  variant="outlined" 
                  sx={{ mb: 1, ml: 1, pl: 4, pr: 4 }} 
                  onClick={() => setTestdata(true)}>
                  Submit Test Data
                </Button>
              )}
              <Card>
                <CardContent>
                  <Typography id="rollout-header" variant="h4" component="div" sx={{ mb: 1.5 }}>ROLLOUT</Typography>
                  <TextField
                    label="SERVICEORDERNUMBER"
                    size="small"
                    margin="normal"
                    placeholder="Enter serviceOrderNumber"
                    value={serviceOrderNumber}
                    onChange={(e) => {
                      setServiceordernumber(e.target.value);
                    }}
                  />
                  <TextField
                    disabled
                    label="STATUS"
                    size="small"
                    margin="normal"
                    defaultValue="NEW"
                  />
                  <TextField
                    label="TRANSACTIONAMOUNT"
                    size="small"
                    margin="normal"
                    placeholder="Enter transactionAmount"
                    value={transactionAmount}
                    onChange={(e) => {
                      const re = /^[+-]?\d*(?:[.,]\d*)?$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        setTransactionamount(e.target.value);
                      }
                    }}
                  />
                  <TextField
                    label="SUBSCRIPTIONUMBER"
                    size="small"
                    margin="normal"
                    placeholder="Enter subscriptionNumber"
                    value={subscriptionNumber}
                    onChange={(e) => {
                      setSubscriptionnumber(e.target.value);
                    }}
                  />
                  <TextField
                    label="MOBILEDEVICENUMBER"
                    size="small"
                    margin="normal"
                    placeholder="Enter mobiledevicenumber"
                    value={mobiledevicenumber}
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        setMobiledevicenumber(e.target.value);
                      }
                    }}
                  />
                  <TextField
                    label="ORIGINALASSETIMEI"
                    size="small"
                    margin="normal"
                    placeholder="Enter originalassetimei"
                    value={originalassetimei}
                    onChange={(e) => {
                      setOriginalassetimei(e.target.value);
                    }}
                  />
                  <TextField
                    label="REPLACEDASSETIMEI"
                    size="small"
                    margin="normal"
                    placeholder="Enter replacedassetimei"
                    value={replacedassetimei}
                    onChange={(e) => {
                      setReplacedassetimei(e.target.value);
                    }}
                  />
                  <TextField
                    label="ID"
                    size="small"
                    margin="normal"
                    placeholder="Enter ID"
                    value={id}
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        setId(e.target.value);
                      }
                    }}
                  />
                  <Typography id="rollout-statement" variant="h6" component="div" sx={{ mt: 1.5 }}>Rollout SQL Statement </Typography>
                  {testdata ? (
                    <Buildrolloutquery queryType="test_data" />
                  ) : (
                    <Buildrolloutquery queryType="record" />
                  )}
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <Typography id="rollback-header" variant="h4" component="div" sx={{ mb: 1.5 }}>ROLLBACK</Typography>
                  <TextField
                    label="SERVICEORDERNUMBER"
                    size="small"
                    margin="normal"
                    placeholder="Enter serviceOrderNumber"
                    value={rb_serviceOrderNumber}
                    onChange={(e) => {
                      setRb_serviceordernumber(e.target.value);
                    }}
                  />
                  <TextField
                    label="STATUS"
                    size="small"
                    margin="normal"
                    value={rb_status}
                    onChange={(e) => {
                      setRb_status(e.target.value);
                    }}
                  />
                  <TextField
                    label="TRANSACTIONAMOUNT"
                    size="small"
                    margin="normal"
                    placeholder="Enter transactionAmount"
                    value={rb_transactionAmount}
                    onChange={(e) => {
                      const re = /^[+-]?\d*(?:[.,]\d*)?$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        setRb_transactionamount(e.target.value);
                      }
                    }}
                  />
                  <TextField
                    label="SUBSCRIPTIONUMBER"
                    size="small"
                    margin="normal"
                    placeholder="Enter subscriptionNumber"
                    value={rb_subscriptionNumber}
                    onChange={(e) => {
                      setRb_subscriptionnumber(e.target.value);
                    }}
                  />
                  <TextField
                    label="MOBILEDEVICENUMBER"
                    size="small"
                    margin="normal"
                    placeholder="Enter mobiledevicenumber"
                    value={rb_mobiledevicenumber}
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        setRb_mobiledevicenumber(e.target.value);
                      }
                    }}
                  />
                  <TextField
                    label="ORIGINALASSETIMEI"
                    size="small"
                    margin="normal"
                    placeholder="Enter originalassetimei"
                    value={rb_originalassetimei}
                    onChange={(e) => {
                      setRb_originalassetimei(e.target.value);
                    }}
                  />
                  <TextField
                    label="REPLACEDASSETIMEI"
                    size="small"
                    margin="normal"
                    placeholder="Enter replacedassetimei"
                    value={rb_replacedassetimei}
                    onChange={(e) => {
                      setRb_replacedassetimei(e.target.value);
                    }}
                  />
                  <TextField
                    label="ID"
                    size="small"
                    margin="normal"
                    placeholder="Enter ID"
                    value={id}
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        setId(e.target.value);
                      }
                    }}
                  />
                  <Typography id="rollback-statement" variant="h6" component="div" sx={{ mt: 1.5 }}>Rollback SQL Statement </Typography>
                  {testdata ? (
                    <Buildrollbackquery queryType="test_data" />
                  ) : (
                    <Buildrollbackquery queryType="rbrecord" />
                  )}
                </CardContent>
              </Card>
            </Box>
          </TabPanel>
          <TabPanel value="2">
            <Box
              component="form"
              sx={{ maxWidth: '100%', '& .MuiTextField-root': { m: 1 } }}
              onSubmit={handleClickOpen}>
              {folderName && <ScriptInfo />}
              {responsealert && <ScriptAlert />}
              {isprocessing && <LinearDeterminate />}
              <LoadingButton
                type="submit"
                variant="contained"
                endIcon={<SendIcon />}
                loading={loadingbutton}
                loadingPosition="end"
                size="large"
                sx={{ mb: 1, ml: 1, pl: 4, pr: 4 }}>
                Submit
              </LoadingButton>
              <ResponsiveDialog formType="submitOutboundForm" />
              {ADgroup === 'HQDOMAIN_Horizon App Support' && (
                <Button variant="outlined" sx={{ mb: 1, ml: 1, pl: 4, pr: 4 }} onClick={() => setTestdata(true)}>
                  Submit Test data
                </Button>
              )}
              <Card>
                <CardContent>
                  <Typography id="rollout-batch" variant="h5" component="div" sx={{ mb: 1.5 }}>ROLLOUT</Typography>
                  <TextField
                    label="OUTBOUNDFILENAME"
                    size="small"
                    margin="normal"
                    placeholder="Enter outboundFilename"
                    value={outboundFilename}
                    onChange={(e) => {
                      setoutboundFilename(e.target.value);
                    }}
                  />
                  {testdata ? (
                    <Buildrolloutquery queryType="test_data" />
                  ) : (
                    <Buildrolloutquery queryType="batch" />
                  )}
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <Typography id="rollback-batch" variant="h5" component="div" sx={{ mb: 1.5 }}>ROLLBACK</Typography>
                  <TextField
                    label="STATUS"
                    size="small"
                    margin="normal"
                    value={rb_status}
                    onChange={(e) => {
                      setRb_status(e.target.value);
                    }}
                  />
                  {testdata ? (
                    <Buildrollbackquery queryType="test_data" />
                  ) : (
                    <Buildrollbackquery queryType="rbbatch" />
                  )}
                </CardContent>
              </Card>
            </Box>
          </TabPanel>
        </TabContext>
      </Card>
    </div>
  );
}

export default UpdatePage;
